html body{
	background: #1a1f30;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	color: #009FD6;
	line-height: 30px;
	font-weight: 400;
}
body h1, body h2, body h3, body h4, body h5, body h6{
	margin: 0 0 10px;
	font-family: 'Roboto', sans-serif;
	color: #009FD6;
	font-weight: 400;
}
body h1{
	font-family: 'Bebas Neue', cursive;
	font-size: 60px;
	line-height: 70px;	
	text-transform: uppercase;
}
body h2{
	font-family: 'Bebas Neue', cursive;
	font-size: 60px;
	line-height: 66px;
}
body h3{
	font-size: 34px;
	line-height: 40px;
}
body h4{
	font-size: 30px;
	line-height: 36px;
}
body h5{
	font-size: 24px;
	line-height: 30px;
}
body h6{
	font-size: 16px;
	line-height: 20px;
}
body a,
body a:hover{
	color: #009DE2;
	text-decoration: none;
}
body ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
body img{
	max-width: 100%;
}
body input:focus,
body button:focus,
body select:focus{
	outline: none;
	box-shadow: none;
}
.font-roboto{
	font-family: 'Roboto', sans-serif;
}
.font-bebas{
	font-family: 'Bebas Neue', cursive;
}
.font-24{
	font-size: 24px;
	line-height: 30px;
}
.color-white,
.color-white h3,
.color-white h4{
	color: #fff;
}
.color-blue{
	color: #009DE2;
}
.color-red{
	color: #EE3331;
}
.color-green{
	color: #5A9A0F;
}
.color-orange{
	color: #E77A2E;
}
.color-light-yellow{
	color: #CBE0A9;
}
.color-light-green{
	color: #00C2BC;
}
.color-light-orange{
	color: #FFA983;
}
.color-light-pink{
	color: #FF99CC;
}
.border-bottom-light-yellow{
	border-bottom-color: #CBE0A9;
}
a.btn{
	padding: 10px 30px;
	border-radius: 8px;	
	font-size: 24px;
	color: #fff;
	font-weight: normal;	
	transition: all ease 0.4s;
}
.btn.btn-small{
	padding: 4px 20px;
	font-size: 18px;
}
.btn.btn-medium{
	padding: 12px 45px 7px;
	font-size: 35px;	
}
.btn.btn-bord{
	border: 2px solid #fff;
}
.btn.btn-bord-white:hover{
	background: #fff;
	color: #000;
}
.btn.btn-bord-gray{
	border-color: #a5a5a5;
	color: #a5a5a5;
}
.btn.btn-bord-gray:hover{
	background: #a5a5a5;
	color: #fff;
}
.btn.btn-bord-blue{
	border-color: #009ee5;
	color: #009ee5;
}
.btn.btn-bord-blue:hover{
	background: #009ee5;	
	color: #fff;
}
.btn.btn-bord-orange{
	border-color: #ff7b22;
	color: #ff7b22;
}
.btn.btn-bord-orange:hover{
	background: #ff7b22;	
	color: #fff;
}
.btn.btn-bord-green{
	border-color: #199b00;
	color: #199b00;
}
.btn.btn-bord-green:hover{
	background: #199b00;	
	color: #fff;
}
.btn.btn-blue{
	background: #009ee5;
	color: #fff;
}
.btn.btn-blue:hover{
	background: #017fb7;
	color: #fff;	
}
.btn.btn-orange{
	background: #ff7b22;
	color: #fff;
}
.btn.btn-orange:hover{
	background: #df610c;
	color: #fff;	
}
.btn.btn-green{
	background: #199b00;
	color: #fff;
}
.btn.btn-green:hover{
	background: #168401;
	color: #fff;	
}
.dark-bg{
	background-color: #1a1a21;
}
.dark-bg-1{
	background-color: #141418;
}
.bg-orange{
	background-color: #ff7b22;
}
.bgcenter{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;	
}
.slick-arrow{
	cursor: pointer;
}
.overlay{
	width: 100%;
	height: 100%;
	background: #000;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0.5;
	visibility: hidden;
	opacity: 0;
	transition: all eaes 0.4s;
}
.show-overlay{
	visibility: visible;
	opacity: 0.9;	
}
body .container{
	max-width: 1370px;
	width: 100%;
}

/*---- Wallet Modal ----*/
.wallet_btns button {
    width: 100%;
    border: none;
    padding: 15px;
    transition: all 0.3s;
}
.wallet_btns button:hover {
    transition: all 0.3s;
    background-color: #BBBBBB;
}
.wallet_btns img {
    width: 45px;
}
.wallet_btns h3 {
    margin-bottom: 5px;
    font-weight: 700;
    margin-top: 10px;
}
/*---- Wallet Modal ----*/

/*---- Header ----*/
.mainhead{
	padding: 30px 0 20px;
	background: #0d0d12;
}
.top-head li{
	margin: 0 0 0 30px;
	padding: 0 0 0 30px;
	position: relative;
}
.top-head li::before{
	width: 1px;
	height: 16px;
	background: #ff7b22;
	display: inline-block;
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
.top-head li:first-child::before{
	display: none;
}
.top-head li a{
	font-size: 18px;
	color: #E77A2E;
}
.mainmenu li{
	margin: 0 0 0 60px;
}
.mainmenu li:first-child{
	margin: 0;
}
.mainmenu li a{
	font-size: 18px;
	color: #fff;
	font-weight: 400;
	transition: all ease 0.4s;
}
.mainmenu li a:hover{
	color: #ff7b22;
}
.mainmenu li a.menu-button, .mint-connect-wallet a.menu-button{	
	padding: 7px 24px 5px;
	background: #009ee5;
	border-radius: 40px;	
	display: inline-block;
	font-size: 16px;
	color: #000;
	font-weight: 700;
	transition: all ease 0.4s;
	-webkit-appearance: none;
}
.mainmenu li a.menu-button:hover, .mint-connect-wallet a.menu-button:hover{	
	background: #ff7b22;
	color: #fff;
}
.mobile-menu-wrap{
	display: none;
}
.hmburger{	
	display: none;
}
.mobile-connect-button{
	display: none;
}
.close-menu{
	display: none;
}

/*---- Home Banner ----*/
.home-banner{
	background: #141418;
}
.home-banner-summary{	
	left: 0;
}
.home-banner-summary h1{
	font-size: 70px;
	line-height: 82px;
	color: #fff;	
}

/*---- Our Projects ----*/
.project-slider-wrap{
	padding: 0 65px;	
}
.our-projects .container{
	max-width: 1500px;
}
.project-slider{
	background: #141418;
}
.project-item{
	padding: 0 10px;
}
.project-item h5{
	margin: 0;
	font-family: 'Bebas Neue', cursive;
}
.project-item-inner{
	background: #454550;
	border-radius: 3px;
	font-size: 17px;
}
.project-img img{
	width: auto;
	max-height: 100px;
}
.project-button a.btn{
	padding: 3px 24px;
	font-size: 14px;
	font-weight: 400;
}
.project-slider-wrap .slick-slider{
	position: static;
}
.project-slider-wrap .slick-arrow{
	top: 50%;
	transform: translateY(-50%);
}
.project-slider-wrap .slick-prev{
	left: 15px;
}
.project-slider-wrap .slick-next{
	right: 15px;
}
.project-slider-wrap .slick-arrow::before{
	display: none;
}
.project-slider-wrap .slick-prev img{
	transform: rotate(180deg);
}
.project-slider-wrap .slick-disabled{
	opacity: 0.4;
	pointer-events: none;
}
.project-view a{
	text-decoration: underline;
}
.project-view a:hover{
	text-decoration: none;	
}

/*---- Our Services ----*/
.our-services{
	background: #141418;	
}
.our-service-head{
	margin: 0;
	padding: 30px 30px 15px;
	background: #1a1f30;	
	border-right: 3px solid #0e131e;
	box-shadow: 0 0 30px rgba(0,0,0,0.1);
	pointer-events: none;
}
.our-service-head i{
	font-size: 24px;
	color: #fff;
	display:  none;	
}
.our-service-col h3{
	margin: 0;
	font-family: 'Bebas Neue', cursive;
	text-transform: uppercase;
}
.our-service-head h3::after{
	width: 22px;
	height: 10px;
	margin: 5px auto 0;
	display: block;
	content: "";	
}
.our-service-col:nth-child(1) h3{
	color: #ff7b22;
}
.our-service-col:nth-child(2) h3{
	color: #00a0d9;
}
.our-service-col:nth-child(3) h3{
	color: #00ff9c;
}
.our-service-col:nth-child(4) h3{
	color: #ed30d1;
}
.our-service-col:nth-child(1) .our-service-head h3::after{
	background: #ff7b22;
}
.our-service-col:nth-child(2) .our-service-head h3::after{
	background: #00a0d9;
}
.our-service-col:nth-child(3) .our-service-head h3::after{
	background: #00ff9c;
}
.our-service-col:nth-child(4) .our-service-head h3::after{
	background: #ed30d1;
}
.our-service-items{
	padding: 30px;
}
.our-service-item{
	margin: 0 0 40px;
	padding: 20px;
	border: 1px solid #2e2e3b;
	border-radius: 10px;
	box-shadow: 20px 20px 20px rgba(0,0,0,0.2);
	font-size: 26px;
	color: #EDD0C0;
}
.our-service-col:nth-child(2n) .our-service-items{
	background: #1a1a21;
}
.our-service-summary-image{
	width: 120px;
	height: 120px;
}
.our-service-more a{
	font-size: 20px;
	color: #fff;
	text-decoration: underline;
}
.our-service-more a:hover{
	text-decoration: none;	
}
.our-service-more{
	padding: 80px 0 0;
}

/*---- Our Success ----*/
.section-head h2{
	padding: 0 0 10px;
	font-size: 100px;
	line-height: 110px;
	color: #fff;
}
.our-success{
	padding: 30px 0 0;
}
.our-success-items{	
	background: #141418;
}
.our-success-item{		
	border: 1px solid #007eb4;
	border-radius: 10px;
	position: relative;
	z-index: 1;
}
.our-success-img{
	height: 190px;
}
.our-success-img-logo{
	width: 130px;
	height: 130px;		
}
.our-success-item::before{
	width: 100%;
	height: 100%;	
	background-image: radial-gradient(#4e4ef5, #000020);	
	display: block;
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
	opacity: 0.3;
}
.our-success-summary{
	color: #EDD0C0;
}
.our-success-summary h3{
	color: #ff7b22;
}
.our-success-slider-buttons{
	display: none;
}

/*---- Category Page ----*/
.category-project{
	padding: 40px 0 30px;
	background: #1a1a21;
}
.category-project-item{	
	background: #0c0c0f;
	border: 2px solid #373748;
	border-radius: 16px;	
}
.category-project-item h3{
	color: #009FD6;
}
.category-project-item-inner{
	padding: 30px 20px 30px;
}
.category-project-logo img{
	max-width: 140px;
}
.category-project-status-buttons a.btn{
	margin: 0 0 8px 8px;
	padding: 4px 12px;
	border-width: 1px;
	border-radius: 4px;
	font-size: 14px;
}
.category-project-summary{
	color: #009FD6;
	font-weight: 700;	
}
.category-project-summary h3{
	font-size: 24px;
	line-height: 30px;
}
.category-project-summary a.btn{	
	padding: 6px 45px;
	font-size: 17px;
	font-weight: 400;
}
.category-project-button a.btn{
	max-width: 200px;
}
.category-project-box-col{
	border-right: 2px solid #1a1a21;
}
.category-project-box-col:nth-child(2),
.category-project-box-col:last-child{
	border: none;
}
.category-project-box{	
	border-width: 3px;
	border-bottom-style: solid;
}
.category-project-box-summary{
	font-size: 13px;
	line-height: 20px;
}
.category-project-box-desc .more-desc{
	width: 10px;
	height: 10px;
	line-height: 8px;
	background: #fff;
	border: 1px solid #ff8181;
	font-size: 10px;
	color: #ff8181;
	font-style: normal;
	text-align: center;
	position: absolute;
	bottom: 6px;
	right: 0;
	cursor: pointer;
}
.category-project-box-rate{	
	font-size: 16px;
	line-height: 24px;
	font-style: italic;
}
.category-project-box-score{
	font-size: 21px;
	font-weight: 700;
}
.category-project-boxes-progress{
	font-size: 19px;
}
.category-project-boxes-progress .progress{
	height: 30px;
	background: none;
	border: 2px solid #00a3ec;
	border-radius: 24px;	
}
.category-project-boxes-progress .progress-bar{
	background: #003850;
}

.buy-claim-buttons {
	position: relative;
}

.total-minted {
	position: absolute;
	top: 0;
	right: 0;
}

.category-project-marketing-items{
	background: #1a1a21;
}
.category-project-marketing-item{
	padding: 0 7px 8px;
}
.category-project-marketing-item img{
	width: 57px;
	height: 57px;
	border-radius: 50%;
}
.category-project-view-button a.btn{
	padding: 18px 10px 12px;
	font-family: 'Bebas Neue', cursive;
	font-size: 36px;
	line-height: 48px;
	font-weight: normal;
	letter-spacing: 3px;
}
.category-project-categories li{
	margin: 0 0 0 20px;
}
.category-project-categories li span{
	cursor: pointer;
}
.category-project-categories li.active span{
	color: #ff7b22;
}

/*---- Launch Sale ----*/
.launch-sale-progress-text ul li{
	width: 20%;
	font-size: 19px;
	line-height: 24px;
}
.launch-sale-progress .progress{
	height: 30px;
	background: none;
	border:  2px solid #009ee5;
	border-radius: 40px;
}  
.launch-sale-progress .progress-bar{
	background: #00425d;
}
.launch-sale .form-field select.form-control{
	width: 250px;	
	padding: 2px 10px 0 10px;
	background: #141418;
	border-bottom: 10px solid #393946;
	border-radius: 8px;	
	box-shadow: 0 5px 5px rgba(0,0,0,0.6);
	color: #D4D1E5;	
}
.launch-sale .form-field select{
  	-webkit-appearance: none;
  	-moz-appearance: none;
  	text-indent: 1px;
  	text-overflow: '';
}
.launch-sale .form-field select::-ms-expand{
    display: none;
}
.launch-sale .form-field button{
	max-width: 260px;
	padding: 12px 30px 7px;
	font-size: 35px;
	letter-spacing: 2px;
}
.launch-sale .form-field i{
	font-size: 24px;
	color: #D4D1E5;
	top: 44%;
	right: 15px;
	transform: translateY(-50%);
	pointer-events: none;
}
.launch-sale-token-form-col-1,
.launch-sale-token-form-col-3{
	width: 45%;
}
.launch-sale-token-form-col-2{
	width: 10%;
}
.launch-sale-form-col{
	padding: 0 50px;
}
.launch-sale-token-form .form-field label,
.launch-sale-presale-form .form-field label{
	font-size: 30px;
	line-height: 40px;
}
.launch-sale-presale-form .form-field P{
	font-size: 24px;
	line-height: 30px;
}
.launch-sale-presale-form .form-field small{
	font-size: 19px;
	line-height: 26px;
}
.launch-sale-presale-form .date-field label{
	font-size: 24px;
	line-height: 30px;
}
.launch-sale-presale-form .date-field input.form-control{
	width: 350px;	
	height: 80px;
	padding: 25px 20px 0;
	background: #141418;
	border: 1px solid #393946;
	border-bottom: 10px solid #393946;
	border-radius: 8px;	
	box-shadow: 0 5px 5px rgba(0,0,0,0.6);
	color: #D4D1E5;	
}
.launch-sale-presale-form .react-datetime-picker__wrapper{
	width: 100%;
	height: 80px;
	padding: 0 12px;
	border: 1px solid #393946;
	border-bottom: 10px solid #393946;
	border-radius: 8px;	
	box-shadow: 0 5px 5px rgba(0,0,0,0.6);
}
.launch-sale-presale-form .react-datetime-picker__inputGroup__input{
	padding-top: 10px;
	font-size: 16px;
	color: #fff;
}
.launch-sale-presale-form .react-datetime-picker__wrapper span.react-datetime-picker__inputGroup__leadingZero{
	font-size: 16px;
	color: #fff;
}
.launch-sale-presale-form .react-datetime-picker__inputGroup__divider{
	padding: 0 5px;
}
.launch-sale-presale-form .react-datetime-picker__clear-button{
	display: none;
}
.launch-sale-presale-form .react-datetime-picker__calendar-button svg{
	opacity: 0;
}
.launch-sale-presale-form .react-calendar{
	padding: 10px;
}
.launch-sale-presale-form .react-calendar__month-view button.react-calendar__tile{
	padding: 10px;
	font-size: 16px;
}
.launch-sale-presale-form .date-field span{
	font-size: 14px;
	color: #D4D1E5;
	top: 7px;
	left: 16px;
}
.launch-sale-presale-form .date-field img{
	top: 15px;
	right: 20px;
	pointer-events: none;
}
.launch-sale-featured-banner-button{
	padding: 0 20px;
}
.pre-book-marketing-item{
	padding: 30px;
	background: #1a1f30;
	border: 2px solid #373748;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);	
	line-height: 24px;
}
.pre-book-marketing-item h5{
	min-height: 60px;
}
.pre-book-marketing-item-score{
	font-size: 30px;
	line-height: 30px;
	font-weight: bold;
}
.pre-book-marketing-item h5{
	font-family: 'Roboto', sans-serif;
}
.pre-book-marketing-item-col{
	padding: 0 20px 40px;
}
.pre-sale-summary-item{
	padding: 0 5px;
}
.pre-sale-summary-item ul{
	margin: 3px 0 0;
}

/*---- User Dashboard Staking ----*/
.staking-nav li{
	margin: 0 0 0 30px;
}
.staking-nav li a{
	padding: 10px 30px;
	border:  2px solid transparent;
	border-radius: 40px;
	display: block;
	font-size: 20px;
	color: #C5D6B0;
	transition: all ease 0.4s;
}
.staking-nav li a:hover,
.staking-nav li.active a{
	border-color: #bfd7b0;
}
.staking,
.whitelist{
	padding: 40px 0 40px;
}
.staking-item{
	font-size: 20px;
	line-height: 28px;
}
.nft-staking{
	padding: 0 0 0 80px;
}
.staking-tabs li{
	padding: 0 10px;
}
.staking-form .form-field{
	width: 100%;
}
.staking-form-field-2{
	flex-basis: 180px;
	padding: 0 0 0 10px;
}
.reward-amount{
	font-size: 30px;
	text-decoration: underline;
}
.reward-button .btn{
	padding-bottom: 5px;
	font-size: 32px;
	color: #000;
}
.staking-thumb img{
	width: 100%;
}
.staking-thumb-summary{
	font-size: 30px;
	line-height: 36px;
}
.whitelist-table table{
	border: none;	
}
.whitelist-table table tr{
	border-bottom: 2px solid #3c2c42;
}
.whitelist-table table tr th{
	padding: 10px 0;
}
.whitelist-table table tr th:nth-child(1){
	width: 22%;
}
.whitelist-table table tr th:nth-child(2){
	width: 22%;
}
.whitelist-table table tr th:nth-child(3){
	width: 56%;
}
.whitelist-table table tr td{
	padding: 15px 5px;
}
.whitelist-table table tr:nth-child(2n){
	background: #131319;
}
.whitelist-table tr td:first-child{
	padding-left: 40px;
}
.whitelist-table tr td a.btn{
	margin: 0 0 0 9px;
	padding: 5px 10px 4px;
	border-width: 1px;
	border-radius: 6px;
	font-size: 14px;
}
.whitelist-table tr td a.btn-bord-green{
	margin: 0 0 0 14px;
}
.whitelist-project{
	max-width: 320px;	
	padding: 20px;
	background: #1a1f30;
	border: 2px solid #373748;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0,0,0,0.4);
}
.whitelist-project-summary h5{
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
}
.whitelist-project-summary a.btn{
	padding: 4px 18px 3px;
	font-size: 13px;
}
.whitelist-project-summary P{
	margin: 0;
}

/*---- Listing ----*/
.project-list h3{
	font-size: 30px;
}
.project-img{
	width: 100px;
	height: 100px;
}
.project-desc{
	font-size: 13px;
	line-height: 20px;	
}
.project-summary-follow li{	
	margin: 0 40px 0 0;
	font-size: 20px;
	line-height: 28px;
}
.project-summary-follow li:last-child{
	margin: 0;
}
.project-summary-follow li span{
	display: block;
	font-size: 14px;
	line-height: 18px;
}
.project-summary-follow li i{
	margin: 0 10px 0 0;
	font-size: 40px;
}
.project-summary-follow li a.btn{
	padding: 2px 35px 1px;
}
.project-vote-buttons{
	font-size: 12px;
	line-height: 14px;
}
.project-vote-buttons li{
	padding: 0 6px;
}
.project-vote-buttons a.btn{
	padding: 4px 18px 2px;
	font-size: 18px;
	color: #000;
}
.project-vote-buttons a.btn i{
	margin: -3px 8px 0 0;
	font-size: 24px;
	color: #fff;
}
.project-vote-count{
	font-size: 18px;
}
.project-vote-count{
	margin: 10px 0 0;
	padding: 5px 24px;	
	background: #02033c;
	border-radius: 4px;
	display: inline-block;
}
.project-vote-share li{
	margin: 0 15px 15px 0;
}
.project-vote-share i{
	width: 42px;
	height: 42px;
	line-height: 42px;
	border-radius: 50%;
	display: block;
	color: #fff;
	text-align: center;
}
.project-vote-share i.fa-twitter{
	background: #33cbfe;	
}
.project-vote-share i.fa-instagram{
	background: #ff1a73;		
}
.project-vote-share .fa-reddit-alien{
	background: #fff;
	color: #000;
}
.project-vote-share i.fa-location-arrow{
	background: #38ace0;	
}
.project-vote-share i.fa-micon{
	background: #000;	
}
.project-vote-share i.fa-facebook{
	background: #0078f7;
}
.project-vote-share i.fa-facebook{
	background: #0078f7;
}
.project-vote-share i.fa-wechat{
	background: #fff;	
}
.project-vote-share a.btn{
	padding: 6px 12px 5px;
	border-width: 1px;
	border-radius: 6px;
	font-size: 14px;
}
.project-list-tab-wrapper{
	padding: 0 0 80px;
}
.project-list-tabs ul{
	border: none;
}
.project-list-tabs ul li{
	padding: 0 20px 0 0;
	position: relative;
}
.project-list-tabs ul li button.nav-link{
	padding: 12px 10px 8px 30px;
	background: #37373d;
	border: 2px solid #51515f;
	border-bottom: none;	
	border-top-left-radius: 12px;
	display: block;
	font-size: 21px;
	color: #000;
	position: relative;
	transition: none;
}
.project-list-tabs ul li button.nav-link:hover{
	border-color: #51515f;
	isolation: unset;
}
.project-list-tabs ul li button.nav-link:hover::before{
	z-index: 1;
}
.project-list-tabs ul li button.nav-link::before,
.project-list-tabs ul li button.nav-link::after{
	display: block;
	content: "";
	position: absolute;	
}
.project-list-tabs ul li button.nav-link::before{
	width: 40px;
	height: 126%;
	background: #37373d;
	border-right: 2px solid #51515f;
	top: 4px;
	right: -22px;
	z-index: 1;
	transform: rotate(-35deg);	
}
.project-list-tabs ul li button.nav-link::after{
	width: calc(100% + 13px);
	height: 2px;	
	background: transparent;	
	bottom: -2px;
	left: 0;
	z-index: 2;
}
.project-list-tabs ul li button.nav-link.active{
	background: #141418;
	border-color: #51515f;
	border-bottom-color: #141418;
	color: #34A6D8;
	z-index: 2;
}
.project-list-tabs ul li button.nav-link.active::before{
	background: #141418;
}
.project-list-tabs ul li button.nav-link.active::after{
	background-color: #141418;
}
.project-list-tabs ul li.active::after{	
	background: #141418;	
}
.project-list-tabs ul li span{
	position: relative;
	z-index: 2;
}
.project-list-tab-wrapper .tab-content{
	padding: 40px 20px;
	background: #141418;
	border: 2px solid #51515f;
	box-shadow: 0 20px 20px rgba(0,0,0,0.4);
	position: relative;
	z-index: 1;
}
.project-list-tab-wrapper .tab-content .tab-video{
	max-width: 600px;
	width: 100%;
	height: 350px;
}
.marketing-buy-form input{
	padding: 0 20px;
	font-size: 28px;
}
.marketing-buy-form button{
	max-width: 200px;
	padding: 10px 20px 4px;
	font-size: 48px;
	color: #000;
}
.marketing-buy-form button:hover{
	color: #000;	
}
.presale-tab-col-2{
	padding: 0 40px;
}
.marketing-logo img{
	width: 78px;
}
.presale-withdraw-item{
	max-width: 180px;
	padding: 10px 10px 5px;
	border-bottom: 5px solid #393946;
	font-size: 13px;
	line-height: 24px;
}
.presale-withdraw-item .color-green{
	font-size: 24px;
	line-height: 30px;
}
.presale-withdraw-item .color-green span{
	font-size: 14px;
}

/*---- Launch Listing ----*/
.exchange-info{
	font-size: 16px;
	line-height: 20px;
}
.exchange-title h4{
	font-size: 22px;
	line-height: 26px;
}
.exchange-title i{
	margin: 0 0 0 5px;
	color: #ff7b22;
}
.exchange-item{
	font-size: 14px;
	color: #D4D1E5;
}
.exchange-field{
	padding: 30px 10px 5px 15px;
	background: #1a1a21;
	border-radius: 8px;
	border-bottom: 8px solid #393946;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	font-size: 15px;
	color: #D4D1E5;
}
.exchange-select select{
	background: none;
	border: none;
	color: #D4D1E5;
	text-align: right;
}
.exchange-field-arrow i{
	width: 40px;
	height: 40px;
	line-height: 38px;	
	border: 1px solid #393946;
	border-radius: 50%;	
	font-size: 24px;
	color: #fff;	
}
.exchange-table{
	padding: 20px;
	border: 1px solid #0070cf;	
}
.exchange-table table tr{
	border-bottom: 2px solid #3c2c42;
}
.exchange-table table tr td{
	padding: 10px;
	font-size: 11px;
	line-height: 16px;
	text-align: right;
}
.exchange-table table tr td:nth-child(1){
	width: 23%;
	text-align: left;
}
.exchange-table table tr td:nth-child(2){
	width: 25%;
	padding-right: 80px;
}
.exchange-table table tr td:nth-child(3){
	width: 25%;
	padding-right: 80px;
}
.exchange-table table tr td:nth-child(4){
	width: 9%;
}
.exchange-table table tr td:nth-child(5){
	width: 9%;
}
.exchange-table table tr td:nth-child(6){
	width: 9%;
	text-align: left;
}
.exchange-widget{
	padding: 10px 12px;
	border: 1px solid #0070cf;
	border-radius: 6px;
}
.exchange-widget-title{
	font-size: 12px;
	line-height: 18px;
	color: #009DE2;
}
.exchange-widget-title i{
	margin: 0 5px 0 0;
}
.exchange-widget{
	font-size: 11px;
	line-height: 14px;
	color: #CBE0A9;
}
.exchange-service-widget img{
	max-height: 21px;
	margin: 0 0 10px;
}
.whitelistdiv{
	display: none;
}
.whitelistdiv.show{
	display: block;
}
.vesting-whitelist input{
	width: 30px;
	height: 30px;
	margin: -2px 12px 0 0;
	background: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

/*---- Input ----*/
.form-field label{
	margin: 0 0 10px;
}
.form-field small{
	display: block;
}
form .form-control{
	width: 100%;
	height: 60px;
	padding: 0 15px;
	background: #c4bed3;
	border: none;	
	border-radius: 0;
	box-shadow: none;
	font-size: 22px;
	color: #000;
}
textarea.form-control{
	height: 330px;
	padding: 15px;
}
.form-control:focus{
	background: #c4bed3;
	box-shadow: none;
}
.form-control:-moz-placeholder{
	color: #000;
	opacity: 1;
}
.form-control::-moz-placeholder{
	color: #000;
	opacity: 1;
}
.form-control::-webkit-input-placeholder{
	color: #000;
	opacity: 1;
}
.form-control:-ms-input-placeholder{
	color: #000;
	opacity: 1;
}
.submit-button{	
	background: #ff7b22;
	border-radius: 4px;
	color: #fff;
	font-weight: bold;
	transition: all ease 0.4s;
}
.submit-button:hover{
	background: #df610c;
}

/*---- Pagination ----*/
.pagination li{
	margin: 0 5px;
}
.pagination li span{
	width: 40px;
	height: 40px;
	padding: 0;
	background: #1a1f30;
	border: 1px solid #373748;
	border-radius: 3px;
	text-align: center;
	cursor: pointer;
}
.pagination li.page-item.active span{
	background: #ff7b22;
	border-color: #ff7b22;
}

/*---- Loader ----*/
.loader-wrap{
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.9);	
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
  	z-index: 99; 
  	display: none;
}
.loader-wrap.active{
	display: flex;
}
.loader {
	width: 60px;
  	height: 60px;
  	border: 7px solid #f3f3f3;
  	border-radius: 50%;
  	border-top: 7px solid #009ee5;  
  	-webkit-animation: loader 2s linear infinite;
  	animation: loader 2s linear infinite;
}
@-webkit-keyframes loader{
	
	0%{ 
		-webkit-transform: rotate(0deg); 
	}
  	100% { 
  		-webkit-transform: rotate(360deg); 
  	}

}
@keyframes loader{

	0%{ 
		transform: rotate(0deg); 
	}
  	100%{ 
  		transform: rotate(360deg); 
  	}

}

/*---- Footer ----*/
.mainfoot{
	background: #0d0d12;
}
.top-foot li{
	margin: 0 30px;
}
.top-foot li a{
	font-family: 'Bebas Neue', cursive;
	font-size: 30px;
	color: #fff;
	transition: all ease 0.2s;
}
.top-foot li a:hover{
	color: #E77A2E;	
}

.customcheckbox {
	display: block;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.customcheckbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.customcheckbox .checkmark {
	position: relative;
	top: 0;
	left: 0;
	height: 45px;
	width: 42px;
	display: inline-block;
}
.customcheckbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.customcheckbox input:checked ~ .checkmark:after {
	display: block;
}
.checkmark img.checkicon {
	position: absolute;
	top: -4px;
	left: 4px;
	visibility: hidden;
	opacity: 0;
}
.customcheckbox input:checked ~ .checkmark .checkicon  {
	opacity: 1;
	visibility: visible;
}
.img-circle img {
	height: 150px;
	width: 150px;
	object-fit: cover;
	border-radius: 50%;	
}
.pre-book-marketing-item-desc {
    margin-top: 20px;
}
.pre-book-marketing-item-desc p {
    min-height: 48px;
}
.launch-sale-presale-form .date-field .liquidity-lockup input.form-control{
	height: 60px;
	padding: 5px 20px 0; 
}
.launch-sale-presale-form .date-field .liquidity-lockup select.form-control{
	padding: 2px 10px 0 10px;
}
.inrlaunchsaledata {
    margin-top: 40px;
    background: #252a3c;
    border: 2px solid #373748;
    border-radius: 16px;
}
.inrlaunchsaledata table {
    width: 100%;
}
.inrlaunchsaledata table th,
.inrlaunchsaledata table td{
	width: 50%;
	padding: 12px 20px;
	color: #f9f9f9;
}
.inrlaunchsaledata tr {
    border-bottom: 1px solid #393e52;
}
.inrlaunchsaledata tr:nth-child(even) {
    background: #2b3042;
}
.review-presale-details span{
	padding: 20px 30px;
	background: #E77A2E;	
	border-radius: 10px;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
}
.error {
    font-size: 14px;
    color: #d1313f;
}
.point-event-none{
	pointer-events: none;
}

/*---- Modal ----*/
.modal{
	padding-left: 15px;
	padding-right: 15px;
}
.modal-dialog {
    height: 100%;
    display: flex;
	justify-content: center;
    align-items: center;
}

/*---- Coming Soon ----*/
.coming-soon span{
	max-width: 600px;
	padding: 80px 0;
	border-radius: 12px;
	font-size: 60px;
}

.wbought .form-field {
    position: relative;
}
.wbought .form-field .bnbiconcont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 16px;
	font-weight:500;
}
.wbought .form-field .bnbiconcont img {
    max-height: 30px;
    margin-right: 8px;
}

/* .error{
	color: rgb(168, 35, 35);
} */

/* Mint page start */
.mintpageMain {
	display: flex;
	align-items: center;
	justify-content: center;
}
.mintpageBG {
	background: #0c0c0f;
	border: 2px solid #373748;
	border-radius: 16px;
	padding: 20px;
}
.wallet-button {
    padding: 7px 24px 5px;
    background: #009ee5;
    border-radius: 40px;
    display: inline-block;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    transition: all ease 0.4s;
    -webkit-appearance: none;
	border: 0;
}
.wallet-button:hover{	
	background: #ff7b22;
	color: #fff;
}
.mintpageMain .font16 {
	font-size: 16px;
}
.mintpageMain .font14 {
	font-size: 14px;
}
.addMintBtn {
	width: 38px;
	height: 38px;
    background: #009ee5;
    border-radius: 40px;
    display: inline-block;
    font-size: 30px;
    color: #000;
    font-weight: 700;
    transition: all ease 0.4s;
    -webkit-appearance: none;
	border: 0;
}
.addMintBtn:hover {
	background: #ff7b22;
	color: #fff;
}
.mintInput {
	width: 100px;
	height: 38px;
	border: solid 2px #009ee5;
	background-color: transparent;
	color: #fff;
	font-size: 26px;
	font-weight: bold;
	border-radius: 6px;
	text-align: center;
	margin: 0 10px;
}
.progressCustom {
	height: 4px !important;
	background-color: #3C3D46 !important;
}
.progressCustom .progress-bar {
	background-color: #009ee5;
	/* width: 25%; */
}

/*---- Responsive ----*/
@media(max-width: 1399px){

	.mainmenu li {
    	margin: 0 0 0 40px;
	}
	.category-project-box-score {
    	font-size: 18px;    	
	}	

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}
}

@media(max-width: 1199px){

	body h1{
		font-size: 56px;
		line-height: 62px;
	}
	body h2{
		font-size: 52px;
		line-height: 58px;
	}
	body h3{
		font-size: 24px;
		line-height: 30px;
	}
	.top-head li a{
		font-size: 16px;
	}
	.mainmenu li {
    	margin: 0 0 0 35px;
	}	
	.mainmenu li a{
		font-size: 16px;
	}
	.mainmenu li a.menu-button, .mint-connect-wallet a.menu-button{
		padding: 4px 15px 2px;
		font-size: 14px;
	}
	.our-service-items {
    	padding: 15px;
	}
	.category-project-box-score {
    	font-size: 15px;
	}	

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

	.whitelist-table table tr th:nth-child(2) {
    	width: 15%;
	}
	.whitelist-table table tr th:nth-child(3) {
    	width: 63%;
	}
	.top-foot li a{
		font-size: 20px;
	}
	.launch-sale-form .col-md-5{
		flex: 0 0 80%;
		max-width: 80%;
	}

}

@media(max-width: 1080px){

	.exchange-table-wrap{
		width: 580px;
	}
	.exchange-table{
		overflow-x: auto;
	}
	.category-project-head .col-md-8{
		flex: 0 0 90%;
		max-width: 90%;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

}

@media(max-width: 1024px){

	.w-50-1024{
		width: 50%;
	}	
	.category-project-box{
		padding-bottom: 20px !important;
	}
	.category-project-view-button a.btn{
		font-size: 28px;
		letter-spacing: 1px;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

}

@media(max-width: 991px){

	.full-991{
		width: 100%;
	}	
	.mainhead{
		padding: 10px 0 20px;
	}
	.logo img{
		max-width: 180px;
	}
	.mainmenu li{
    	margin: 0 0 0 18px;
	}
	.mainmenu li a{
		font-size: 15px;
	}
	.section-head h2 {	    
	    font-size: 60px;
	    line-height: 70px;	    
	}	
	.our-services .our-service-col{
		width: 50%;
	}	
	.category-project-info{
		justify-content: center;
	}	
	.category-project-buttons{
		padding-top: 30px;
		text-align: center !important;
	}	
	.contract-progress-text ul li{
		font-size: 15px;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

	.nft-staking {
    	padding: 0 0 0 20px;
	}
	.whitelist-table table tr th:nth-child(2) {
    	width: 28%;
	}
	.whitelist-table table tr th:nth-child(3) {
    	width: 50%;
	}
	.project-vote{
		padding-top: 50px;
	}
	.project-vote-share ul{		
		justify-content: center;		
	}	
	.project-list-tabs ul li a{
		padding: 8px 4px 8px 15px;
		font-size: 18px;
	}
	.project-list-tabs ul li a::after{
		right: -25px;
	}
	.launch-sale-progress-text ul li{
		font-size: 15px;
	}
	.top-foot li {
    	margin: 0 18px;
	}
	.top-foot li a{
		font-size: 17px;
	}
	.ads-banners .ads-banner-item-col{
		flex: 0 0 50%;
		max-width: 50%;
		padding-bottom: 20px;
	}
	.project-summary{
		padding: 0 0 0 20px;
	}
	.category-project-boxes .category-project-box-col {
		max-width: 50%;
		flex: 0 0 50%;
	}
	.category-project-head .col-md-8{
		flex: 0 0 100%;
		max-width: 100%;
	}
	.category-project-info{
		flex-direction: column;
	}
	.category-project-status-buttons{
		margin-top: 20px;
	}

}

@media(max-width: 768px){

	.btn{
		padding: 10px 24px;
		font-size: 18px;
	}
	.mainhead{
		padding: 15px 0;
	}
	.top-head{
    	display: none;
	}	
	.mainhead .bottom-head{		
		align-items: center !important;
	}
	.mainmenu ul{
		display: none !important;
	}
	.mobile-logo img{
		max-width: 160px;
	}
	.mobile-menu-wrap{		
		width: 100%;
		height: 100%;
		padding: 10px 20px 20px;
		background: #0d0d12;
		position: fixed;
		top: 0;
		left: -110%;
		z-index: 99;
		transition: all ease 0.4s;
		display: flex;
		flex-direction: column;
	}
	.showmenu{
		left: 0;
	}	
	.mobile-menu li{
		width: 100%;
		margin: 0 0 8px;
		padding: 0 0 8px;
		border-bottom: 1px dashed #2e2e2e;
	}
	.mobile-menu li a{
		font-size: 15px;
		color: #fff;
	}
	.mobile-menu li a.menu-button, .mint-connect-wallet a.menu-button{
		padding: 3px 18px 2px;
	    background: #009ee5;
	    border-radius: 40px;
	    display: inline-block;
	    font-size: 14px;
	    color: #000;
	    font-weight: 700;
	    transition: all ease 0.4s;
	}	
	.mobile-support-menu{
		margin-top: auto;
		display: flex;
	}
	.mobile-support-menu li{
		margin: 0 15px 0 0;
		padding: 0 15px 0 0;
		position: relative;
	}
	.mobile-support-menu li:last-child{
		margin: 0;
		padding: 0;
	}
	.mobile-support-menu li a{		
		font-size: 15px;
		color: #E77A2E;
	}
	.mobile-support-menu li::after{
	    width: 1px;
	    height: 12px;
	    background: #ff7b22;
	    display: inline-block;
	    content: "";
	    position: absolute;
	    top: 50%;
	    right: 0;
	    transform: translateY(-50%);
	}
	.mobile-support-menu li:last-child::after{
		display: none;
	}
	.hmburger{
		width: 26px;		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.hmburger span{
		width: 100%;
		height: 1px;
		margin: 0 0 5px;
		background: #fff;	
		display: block;
	}
	.hmburger span:last-child{
		margin: 0;
	}
	.mobile-connect-button{
		/* margin-left: auto; */
		margin-left: 50px;
		margin-right: 20px;
		display: block;
	}
	.mobile-connect-button a{
		padding: 7px 24px 5px;
		background: #009ee5;
		border-radius: 40px;
		display: inline-block;
		font-size: 16px;
		color: #000;
		font-weight: 700;
		transition: all ease 0.4s;
	}	
	.close-menu{
        width: 26px;
        height: 26px;
        display: block;
        top: 25px;
        right: 12px;
        z-index: 9;
        display: block;
    }
    .close-menu span{
        width: 100%;
        height: 2px;
        background: #fff;
        display: block;
    }
    .close-menu span:first-child {
        transform: rotate(46deg);
        position: relative;
        top: 2px;
    }
    .close-menu span:last-child {
        transform: rotate(-45deg);
    }
	.home-banner-summary h1{
		font-size: 48px;
		line-height: 56px;
	}
	.home-banner-buttons{
		padding-top: 20px !important;
	}
	.ads-banners{
		padding-top: 20px !important;
		padding-bottom: 20px !important;
	}
	.ads-banners .ads-banner-item-col{
		flex: 0 0 100%;
		max-width: 100%;
		padding-bottom: 20px;
	}	
	.project-slider-wrap {
    	padding: 0 50px;
	}
	.project-slider-wrap .slick-prev{
    	left: 10px;
	}
	.project-slider-wrap .slick-next{
    	right: 10px;
	}
	.pre-book-marketing-item h5{
		min-height: inherit;
	}	
	.our-services .our-service-col{
		width: 100%;
	}
	.our-service-head{
		padding: 20px;
		border: none;
		border-bottom: 3px solid #0e131e;
		display: flex;				
		justify-content: space-between;
		align-items: center;
		pointer-events: auto;
	}
	.our-service-head.active i{
		transform: rotate(180deg);
	}
	.our-service-head h3::after{
		height: 5px;
		margin: 5px 0 0;
	}
	.our-service-head i{    	
    	display: block;
	}	
	.our-service-items{
		display: none;
	}
	.our-service-items.show{
		display: block;
	}	
	.our-success-items{
		padding-bottom: 10px !important;
	}
	.category-project-boxes-progress{
		padding-top: 20px !important;
	}
	.staking-nav li {
    	margin: 0 0 0 10px;
	}
	.staking-nav li a{
	    padding: 6px 20px 5px;	    
	    font-size: 15px;	    
	}
	.nft-staking {
    	padding: 0;
	}
	.launch-sale-token-form-col-1, 
	.launch-sale-token-form-col-3 {
    	width: 100%;
	}
	.launch-sale-progress-text ul li{
		font-size: 16px;
	}
	.whitelist-table table tr th:nth-child(2) {
    	width: 20%;
	}
	.whitelist-table table tr th:nth-child(3) {
    	width: 58%;
	}
	.project-list-detail .col-md-4,
	.project-list-detail .col-md-8{
		padding-left: 0;
		padding-right: 0;
	}
	.project-list-button a.btn{
		display: inline-block !important;
	}
	.project-summary {
    	padding: 20px 0 0;
	}
	.project-list-image,
	.project-list-info,
	.project-list-info .project-list-title,
	.project-list-info .project-list-button{
		text-align: center;
	}	
	.project-list-button button{
		margin: 0 auto;
	}
	.project-list-image{
		padding-bottom: 20px;
	}
	.category-project-box-summary{
		display: flex;
		align-items: center;
	}
	.category-project-box-summary .category-project-box-rate{
		margin: 0 !important;
	}
	.category-project-box-score{
		padding: 0 0 0 10px;
	}
	.category-project-info{
		flex-direction: row;
	}
	.category-project-status-buttons{
		margin-top: 0;
	}
	.category-project-status-buttons a.btn{
		margin-bottom: 0;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

	.project-list-tab-wrapper .tab-content{
		padding: 30px 20px;
	}
	.presale-tab-col-2{
		padding: 0;
	}	
	.launch-sale-progress-text{		
		margin: 0 0 8px;
		padding: 0 0 10px !important;
		overflow-x: auto;
	}
	.launch-sale-progress-text ul{
		width: 700px;		
	}	
	.project-summary-follow ul{
		justify-content: center;
	}
	.project-summary-follow li{
		margin: 0 30px;
	}
	.presale-tab-col-1{
		padding-top: 40px;
		order: 3;
	}
	.presale-tab-col-2{
		order: 1;
	}
	.presale-tab-col-3{
		order: 2;
	}
	.project-list-tabs .tab-pane{
		font-size: 14px;
		line-height: 28	px;
	} 
	.launch-sale-presale-form .col-md-4 .form-field.ps-5{
		padding: 0 !important;
	}
	.launch-sale .form-field select.form-control.mobile-full{
		width: 100%;
	}
	.launch-sale-token-form .form-field label, .launch-sale-presale-form .form-field label {
		font-size: 20px;
		line-height: 26px;
	}
	.launch-sale-presale-form .form-field P {
		font-size: 18px;
		line-height: 20px;
	}
	.ads-banner-item-col:not(:first-child){
		display: none;
	}	
	.our-success-items{
		padding-left: 50px;
		padding-right: 50px;
	}
	.our-success-item{
		max-width: 300px;
		margin: 0 auto;
	}
	.our-success-img img{
		margin: 0 auto;
	}
	.our-success-items .slick-arrow{
    	top: 50%;
    	transform: translateY(-50%);
	}	
	.our-success-items .slick-arrow.slick-prev img{
    	transform: rotate(180deg);
	}
	.our-success-items .slick-disabled{
    	opacity: 0.4;
    	pointer-events: none;
	}
	.our-success-items .slick-arrow::before{
		display: none;
	} 	
	.our-success-items .slick-prev{
		left: -30px;
	}
	.our-success-items .slick-next{
		right: -30px;
	}

}

@media(max-width: 640px){

	.contract-progress-text ul li{
		font-size: 13px;
	}
	.whitelist-table table tr th:nth-child(2) {
    	width: 15%;
	}
	.whitelist-table table tr th:nth-child(3) {
    	width: 64%;
	}
	.whitelist-table table tr th{
		font-size: 17px;
	}
	.whitelist-table table tr td{
		font-size: 16px;
	}	
	.project-list-tabs {
    	padding: 0;
	}
	.project-list-tabs ul li a::before,
	.project-list-tabs ul li::after{
		display: none;
	}
	.project-list-tabs ul.nav{
		flex-direction: column;
	}
	.project-list-tabs ul.nav li{
		margin: 0;
		padding: 0;
	}
	.project-list-tabs ul.nav li a{
		border-radius: 0;
	}
	.marketing-buy-form button{	    
		max-width: 150px;
	    padding: 5px 15px 2px;
	    font-size: 30px;	    
	}
	.launch-sale-token-form .form-field label, 
	.launch-sale-presale-form .form-field label {
    	font-size: 18px;
    	line-height: 24px;
	}
	.project-list-tabs ul li button.nav-link{
		width: 100%;
		padding: 8px 4px 8px 15px;
		border-radius: 0;
		font-size: 18px;
		color: #fff;
		text-align: left;
	}
	.project-list-tabs ul li button.nav-link.active{
		background: #ff7b22;
		color: #fff;
	}
	.project-list-tabs ul li button.nav-link::before,
	.project-list-tabs ul li button.nav-link::after{
		display: none;
	}
	.launch-sale-form .col-md-5 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.launch-sale-form h4{
		font-size: 22px;		
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}
	
}

@media(max-width: 575px){

	h1{
		font-size: 42px;
		line-height: 48px;
	}
	h2{
		font-size: 42px;
		line-height: 48px;	
	}
	h4{
		font-size: 24px;
		line-height: 30px;
	}
	a.btn{
		padding: 8px 16px;
		font-size: 16px;
	}
	.w-50-1024{
		width: 100%;
	}
	.font-24 {
    	font-size: 18px;
    	line-height: 26px;
	}
	.form-field.mb-5{
		margin-bottom: 20px !important;
	}
	textarea.form-control {
    	height: 150px;    	
	}
	.home-banner-summary h1{
    	font-size: 30px;
    	line-height: 34px;
	}
	.home-banner-buttons{
		padding-top: 10px !important;
	}
	.home-banner-button.pe-4{
		padding-right: 10px !important;
	}
	.ads-banner-item-col{
		padding-bottom: 20px;
	}
	.project-slider-wrap {
    	padding: 0 35px;
	}
	.project-slider-wrap .slick-prev{
    	left: 0;
	}
	.project-slider-wrap .slick-next{
    	right: 0;
	}
	.our-service-col{
		width: 100%;
	}
	.section-head h2 {
    	font-size: 30px;
    	line-height: 36px;
	}
	.our-success-item-col {
    	flex: 0 0 100%;
    	max-width: 100%;    	
	}
	.category-project-item-inner {
    	padding: 30px 20px 30px;
	}
	.whitelist-table{
		overflow-x: auto;
	}
	.whitelist-table-wrap{
		width: 600px;		
	}
	.project-list h3 {
    	font-size: 24px;
    	line-height: 30px;
	}
	.project-summary-follow li{			
		margin: 0 20px;
	}
	.launch-sale-form .pt-5{
		padding-top: 10px !important;
	}
	.launch-sale-featured-banner-buttons{
		flex-direction: column;
	}
	.launch-sale-featured-banner-button a.btn{
		max-width: 300px;
		width: 100%;
	}	
	.top-foot li{
    	margin: 0 10px;
	}
	.top-foot li a{
    	font-size: 15px;
	}
	.bottom-foot{
		font-size: 13px;
	}
	.coming-soon span{
		padding: 50px 0;
		border-radius: 10px;
		font-size: 40px;
	}
	.connectMob {
		flex-wrap: wrap;
	}
	.connectMob div {
		text-align: center;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

}

@media(max-width: 480px){

	body h1 {
		font-size: 48px;
		line-height: 54px;
	}
	.staking-nav li{
		margin: 0 0 0 5px;
	}
	.staking-nav li a{
	    padding: 4px 15px 3px;
	    font-size: 13px;	    
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}
}

@media(max-width: 420px){

	.mobile-connect-button{
		margin-right: 10px;
		margin-left: 50px;
	}
	.mobile-connect-button a {
		padding: 4px 12px 2px;		
		font-size: 14px;		
	}
	.staking-thumb-summary {
	    font-size: 22px;
	    line-height: 28px;
	}
	.reward-amount {
    	font-size: 22px;    	
	}
	.presale-withdraw-items{
		flex-direction: column;
		align-items: center;
	}
	.pre-sale-summary-items{
		flex-direction: column;
	}
	.category-project-boxes .category-project-box-col {
		max-width: 100%;
		flex: 0 0 100%;
	}
	.category-project-info{
		flex-direction: column;
	}
	.category-project-status-buttons{
		margin-top: 20px;
	}
	.category-project-status-buttons a.btn{
		margin-bottom: 0;
	}
	.category-project-summary{
		text-align: center;
	}
	.category-project-box-col{
		border: none
	}
	.category-project-box-col{
		margin: 0;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

}

@media(max-width: 375px){

	.btn{		
		padding: 6px 12px;
		border-radius: 4px;
		font-size: 14px;
	}	
	.top-head li a {
    	font-size: 15px;
	}	
	.mobile-connect-button a {
		padding: 3px 10px 2px;		
		font-size: 12px;		
	}
	.home-banner-summary h1{
    	font-size: 26px;
    	line-height: 32px;
	}
	.project-item-inner .col-6{
		width: 100%;
	}
	.project-img{
		padding-bottom: 10px;
	}
	.our-success-item-col {
    	flex: 0 0 100%;
    	max-width: 100%;    	
	}
	.category-project-boxes-progress{
    	font-size: 17px;
	}
	.category-project-view-button a.btn{
		font-size: 36px;
		letter-spacing: 1px;
	}
	.category-project-info{
		flex-direction: column;
		text-align: center;
	}
	.category-project-summary {
    	padding: 15px 0 0 0 !important;
	}
	.category-project-button{
		padding-bottom: 12px !important;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

	.staking-form form{
		flex-wrap: wrap;
	}
	.staking-form-field-2 {
    	flex-basis: 100%;
    	padding: 10px 0 0 0;
	}
	.staking-nav li a{
	    padding: 4px 12px 3px;
	    font-size: 12spx;
	}
	.launch-sale .form-field button {
	    max-width: 180px;
	    padding: 12px 30px 7px;
	    font-size: 28px;	    
	}
	.launch-sale-form h4{
		font-size: 18px;
		line-height: 26px;
	}
	.coming-soon span{		
		font-size: 30px;
	}

}

@media(max-width: 360px){

	.logo img {
		max-width: 140px;
	}
	.project-list-tab-wrapper .tab-content .tab-video{		
		height: 300px;
	}
	.project-list-tab-wrapper .tab-content {
		padding: 30px 15px;
	}
	.project-list-tabs ul li{
		padding: 0;
	}
	.launch-sale-presale-form .react-datetime-picker__inputGroup__divider{
		padding: 0 2px;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

}

@media(max-width: 320px){

	.nft-reward .col-6{
		width: 100%;
	}

	.buy-claim-buttons{
		position: unset;
	}

	.total-minted {
		position: unset;
	}

}
.link-btn-dash {
	background-color: transparent;
	font-size: 18px;
    color: #fff;
    font-weight: 400;
    transition: all ease 0.4s;
	border: none;
	cursor: pointer;
}
